'use client';

import { useEffect } from 'react';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import classNames from 'classnames';
import Typography from '../src/components/Typography';
import CategoryGrid from '../src/components/CategoryGrid';
import track from '../src/analytics/track';
import templates from '../src/analytics/templates';

function Home({ featuredSubcategories = [], locale, siteData = {} }) {
  const desktopBanner = siteData?.banner;
  const mobileBanner = siteData?.mobileBanner || siteData?.banner;

  // address values
  const address = siteData.location?.address;
  const city = siteData.location?.city;
  const state = siteData.location?.state;
  const zip = siteData.location?.zip;
  const locationName = siteData.location?.name;

  // pathname
  const pathname = usePathname();

  // translations
  const t = useTranslations('Home');

  // get template name for analytics
  const template = templates[pathname] || '';

  useEffect(() => {
    // update customer data for analytics
    const ecommerce = {
      action: {
        customer: {
          number: siteData.location?.number || '',
          store: siteData.location?.name,
          status: siteData.tenant_status,
          testAccount: `${siteData.tenant_status === 'internal'}`,
        },
      },
    };

    track.page({
      language: locale,
      template,
      ecommerce,
    });

    // eslint-disable-next-line no-console
    console.log('site data:', siteData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    locale,
    template,
    siteData.location?.name,
    siteData.location?.number,
    siteData.tenant_status,
  ]);

  return (
    <main className="wrapper home" tabIndex="-1" id="main">

      {desktopBanner && (
        <div className="image-container brand-image-container desktop">
          <Image
            alt={siteData?.brandName || ''}
            className="image brand-banner"
            fill
            priority="true"
            src={desktopBanner}
          />
        </div>
      )}

      {mobileBanner && (
        <div className="image-container brand-image-container mobile">
          <Image
            alt={siteData?.brandName || ''}
            className="image brand-banner"
            fill
            priority="true"
            src={mobileBanner}
          />
        </div>
      )}

      <div className="welcome-section">
        {siteData?.brandCopy && (
        <div className="welcome-message welcome-box">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: siteData.brandCopy }} />
        </div>
        )}

        {locationName && address && city && state && zip && (
          <div
            className={classNames(
              'store-address welcome-box',
              { 'full-width': !siteData.brandCopy },
            )}
          >
            <Typography variant="eyebrow" addClasses="box-label">
              {t('storeInfo.subhead')}
            </Typography>
            <Typography variant="heading-600" htmlTagOverride="h2" addClasses="box-title">
              {locationName}
            </Typography>
            <Typography htmlTagOverride="p" variant="body" addClasses="brand-copy">
              {address}
              <br />
              {`${city}, ${state} ${zip}`}
            </Typography>
          </div>
        )}
      </div>

      {!!featuredSubcategories.length && (
        <CategoryGrid
          categories={featuredSubcategories}
          dataContentName="featured-categories"
          headline={t('featuredCategories.headline')}
        />
      )}
    </main>
  );
}

export default Home;
