const templates = {
  '/': 'home',
  '/checkout': 'checkout',
  '/configurator': 'configurator',
  '/confirmation/[orderid]': 'order confirmation',
  '/locations': 'store locator',
  '/order/[orderid]': 'order status',
  '/product/[product]': 'product',
  '/search': 'search',
  '/[category]': 'category',
  '/[category]/[subcategory]': 'subcategory',
};

export default templates;
