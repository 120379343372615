/* eslint-disable no-underscore-dangle, max-len */
const track = {
  page: (info) => {
    if (typeof window !== 'undefined') {
      // gtm "Page View" is triggerd by a "History Change"
      // setting within the google analytics admin UI
      // so no 'event' is required here (other than for mtm)
      const gtmObj = {
        page: {
          pageInfo: {
            pageTitle: info.pageTitle || document.title,
            destinationURL: info.destinationURL || window.location.href,
            language: info.language || 'en',
            template: info.template || '',
            region: 'us',
          },
        },
        ...(info.event && { event: info.event }),
        ...(info.eventInfo && { eventInfo: info.eventInfo }),
        ...(info.ecommerce && { ecommerce: info.ecommerce }),
      };

      // mtm "Page View" is triggered by the "pageview" event
      // this will also trigger an event in GA, which is
      // setup in the MTM admin UI (we ignore the event in GA)
      const mtmObj = { ...gtmObj };
      mtmObj.event = 'pageview';

      window._mtm?.push(mtmObj);
      window.dataLayer?.push(gtmObj);
    }
  },

  search: (info) => {
    const obj = {
      page: {
        pageInfo: {
          feature: 'bakery search',
          siteSection: 'top-main-content',
          pageTitle: info.pageTitle,
          destinationURL: info.destinationURL,
          language: info.language || 'en',
          template: info.template || '',
        },
      },
      event: 'async',
      eventInfo: {
        category: 'site search',
        action: 'completed',
        details: 'results',
        value: 1,
        elementDetails: 'form and button',
        searchResultsCount: info.resultCount,
        searchCharacters: info.characters,
        searchCharacterCount: info.characterCount,
        searchTerm: info.term,
      },
    };

    window._mtm?.push(obj);
    window.dataLayer?.push(obj);
  },

  virtualPageView: (info) => {
    if (typeof window !== 'undefined') {
      const obj = {
        page: {
          pageInfo: {
            pageTitle: info.pageTitle,
            destinationURL: info.destinationURL,
            language: info.language || 'en',
            template: '',
          },
        },
        event: 'virtual-pageview',
      };

      window._mtm?.push(obj);
      window.dataLayer?.push(obj);
    }
  },

  ecommerce: (info) => {
    if (typeof window !== 'undefined') {
      const obj = {
        event: info.event,
        eventInfo: info.eventInfo,
        ecommerce: info.ecommerce,
      };

      // Clear the previous ecommerce object.
      window.dataLayer?.push({ ecommerce: null });
      window._mtm?.push({ ecommerce: null });

      window._mtm?.push(obj);
      window.dataLayer?.push(obj);
    }
  },

  // generic event tracking
  event: (info) => {
    if (typeof window !== 'undefined') {
      const obj = {
        event: 'async',
        page: {
          pageInfo: {
            pageTitle: document.title,
            destinationURL: window.location.href,
            language: info.language || 'en', // language page is set to
            template: info.page.pageInfo.template, // name of template type
            region: 'us', // us, uk, etc., based on region
          },
        },
        eventInfo: info.eventInfo,
      };

      window._mtm?.push(obj);
      window.dataLayer?.push(obj);
    }
  },
};

export default track;
